<!-- 商品列表 -->
<template>
  <div :lang="$i18n.locale">
		<div class="listHeader">
			<i></i><span>{{$t('mouldListPage.pageTitle')}}</span>
		</div>
    <!-- <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="auto"
      style="margin:10px 0 0 20px">
      <el-form-item label prop="companyProductCode">
        <el-input v-model="queryParams.companyProductCode" placeholder="请输入客户商品编码" clearable />
      </el-form-item>
			<el-form-item label prop="productCode">
			  <el-input v-model="queryParams.productCode" placeholder="请输入系统商品编码" clearable />
			</el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleQuery">搜索</el-button>
				<el-button icon="el-icon-refresh" @click="handleRefresh">刷新</el-button>
      </el-form-item>
    </el-form> -->

    <!-- 展示 -->
    <el-table style="margin:0 15px;width: calc(100% - 30px)" v-loading="loading" :data="dataList" border>
			<el-table-column type="index" width="50" align="center"></el-table-column>
      <el-table-column :label="$t('mouldListPage.templateName')" align="center" prop="dictLabel"/>
      <el-table-column :label="$t('mouldListPage.templateUrl')" align="center" prop="dictValue">
				<template slot-scope="{ row }">
				  <el-button
				    size="mini"
				    type="text"
				    icon="el-icon-download"
				    @click="handleDownload(row)"
				  >{{$t('commonInfo.actionDownload')}}</el-button>
				</template>
			</el-table-column>
    </el-table>
		<!-- <div class="pageBottom">
		  <el-pagination
		    @size-change="handleSizeChange"
		    @current-change="handleCurrentChange"
		    :current-page="queryParams.page"
		    :page-sizes="[10, 20, 30, 40]"
		    :page-size="queryParams.limit"
		    layout="total, sizes, prev, pager, next, jumper"
		    :total="total"
		  ></el-pagination>
		</div> -->
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      queryParams: {
				page:1,
				limit:10,
				dictType:'sys_template_url'
      },
			loading:false,
			dataList:[],
			total:0,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
	},
  //方法集合
  methods: {
		
		// 分页条数
		handleSizeChange(size) {
		  this.queryParams.limit = size;
		  this.queryParams.page = 1;
		  this.getList();
		},
		// 分页页数
		handleCurrentChange(page) {
		  this.queryParams.page = page;
		  this.getList();
		},
		async getList() {
			this.loading = true;
			this.dataList = [];
			const res = await this.$http.get("/toborder/system/dict/data/list",{
        params: this.queryParams
      });
			
			if (res.code === 200) {
			  this.loading = false;
				let that = this;
				let data = res.rows;
				data.forEach(item => {
					if(item.dictValue == 'url_value'){
						that.dataList.push(item);
					}
				})
				this.total = res.total;
			} else {
			  this.loading = false;
			  this.$message.error(res.msg);
			}
		},
		//下载模板
		handleDownload(row){
			window.open(row.remark);
		},
		
		handleQuery(){
			
		},
  },
  created() {
		this.getList();
	},
  mounted() {}
};
</script>
<style scoped>
	.listHeader{
		width: 100%;
		height: 50px;
		line-height: 50px;
		font-size: 22px;
		padding: 10px 0px;
	}
	.listHeader i{
		width: 4px;
		height: 40px;
		display: inline-block;
		background-color: #0077AA;
		float: left;
		margin: 5px 10px;
	}
	.listHeader span{
		display: inline-block;
		float: left;
	}
	.pageBottom {
	  padding: 20px;
	}
</style>
<style scoped lang="scss">
	// 阿拉伯语
	[lang="ar"] {
		.listHeader{
			i{
				float: right;
			}
			span{
				float: right;
			}
		}
		
		::v-deep .el-form-item__label{
			float: right;
		}
		.el-form-item__content{
			.el-button--primary{
				margin-left: 10px;
			}
		}
		// 表格
		::v-deep .el-table__fixed-right{
			left: 0;
			right: auto;
			.el-table__fixed-body-wrapper{
				left: 0;
				right: auto;
			}
		}
		::v-deep .el-table__fixed-right .el-table__fixed-header-wrapper{
			left: 0;
			right: auto;
		}
		::v-deep .fixed-width .el-button{
			margin: 0 5px 0 0 !important;
			i{
				margin-left: 5px;
			}
		}
		::v-deep .el-dropdown{
			margin: 0 5px 0 0 !important;
			.batchInfoItem{
				width: 20px;
				height: 20px;
				left: -15px;
				right: auto;
				top:-5px;
			}
			span{
				margin-left: -5px;
				float: right;
			}
			i{
				float: right;
				margin: 6px 0 0 5px;
			}
		}
		::v-deep .is-dot {
			left: -2px;
			right: auto;
			top: 6px;
		}
		::v-deep .el-dialog__headerbtn{
			right: auto;
			left: 20px;
		}
		::v-deep .el-dialog__footer {
			text-align: left;
			.el-button {
				margin: 0 10px;
			}
		}
		::v-deep .el-select .el-tag{
			margin: 0 5px;
		}
		::v-deep .el-select .el-tag__close.el-icon-close{
			right: 5px;
		}
		::v-deep .el-tabs__nav{
			float: right;
		}
		.dialog_orderNo {
			label{
				float: right;
				margin-right: 40px;
			}
		}
		::v-deep .el-timeline-item__node--normal{
			right: -15px;
		}
	}
</style>
